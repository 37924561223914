import { useUserAgent } from '@/src/hooks/useUserAgent';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { VoteBtn } from '@/src/components/VoteBtn/VoteBtn';
import { PlayBtn } from '@/src/components/PlayBtn';
import 'swiper/css';
import styles from './Home.module.scss';
import icon_1 from './images/icon_1.png';
import icon_2 from './images/icon_2.png';
import icon_3 from './images/icon_3.png';
import icon_4 from './images/icon_4.png';
import { isMobile } from 'react-device-detect';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';
const videoName = 'video_cover';

export const Home = () => {
  const { browser } = useUserAgent();
  const isBrowserEdge = browser.name === 'Edge';

  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const handleClickToPlay = () => {
    changePage('preloader');
  };

  return (
    <div className={styles.home}>
      <div className={cn(styles.container, 'container')}>
        <Header />
        <div className={cn(styles.media, 'blur_edges')}>
          <div className={styles.media_blur}></div>
          <video
            preload={isMobile ? 'metadata' : 'none'}
            playsInline
            poster={`./videos/home/posters/${videoName}.jpg`}
            width="100%"
            height="100%"
            autoPlay
            muted
            loop
          >
            {isBrowserEdge ? (
              <>
                <source
                  src={`./videos/home/${videoName}.mp4`}
                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={`./videos/home/${videoName}.webm`}
                  type='video/webm;codecs="vp8, vorbis"'
                />
              </>
            ) : (
              <>
                <source
                  src={`./videos/home/${videoName}.mp4`}
                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={`./videos/home/${videoName}.webm`}
                  type='video/webm;codecs="vp8, vorbis"'
                />
              </>
            )}
          </video>
        </div>
        <div className={styles.main}>
          <h1 className={cn(styles.title, 'title_0')}>
            Эта игра покажет,
            <br />
            в&#160;чем&#160;твоя суперсила!
          </h1>
          <div className={styles.btns}>
            <PlayBtn
              classes={cn(styles.btn_play, 'btn_bg', 'btn_car')}
              onClick={handleClickToPlay}
            />
            <VoteBtn classes={cn(styles.btn_support, 'btn', 'btn_heart')} />
          </div>
          <div className={styles.info}>
            <h2 className={cn(styles.info_title, 'title_1')}>
              Что нужно делать?
            </h2>
            <Swiper
              watchOverflow={true}
              slidesPerView={'auto'}
              spaceBetween={16}
              slideToClickedSlide={true}
              breakpoints={{
                699: {
                  spaceBetween: 24,
                },
              }}
            >
              <SwiperSlide>
                <div className={styles.info_icon}>
                  <img src={icon_1} width={86} height={86} alt="Иконка" />
                </div>
                <p>
                  Выбери направление&#160;–
                  <br />
                  офис или&#160;отделение банка
                </p>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.info_icon}>
                  <img src={icon_2} width={86} height={86} alt="Иконка" />
                </div>
                <p>Укажи, в&#160;каком отрезке времени хочешь оказаться</p>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.info_icon}>
                  <img src={icon_3} width={86} height={86} alt="Иконка" />
                </div>
                <p>Принимай решения, которые раскроют твою суперсилу</p>
              </SwiperSlide>
              <SwiperSlide>
                <div className={styles.info_icon}>
                  <img src={icon_4} width={86} height={86} alt="Иконка" />
                </div>
                <p>
                  Укажи её
                  <br />
                  в&#160;своём резюме!
                </p>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <Footer classes={styles.footer} />
      </div>
    </div>
  );
};
