import cn from 'classnames';
// import sendEventToCounters from '@/src/countersEvents.js';
import hh_logo from '../../images/hh_logo.svg';
import styles from './Footer.module.scss';
import { VACANCIES_HREF } from '@/src/constants';

type TProps = {
  classes?: string;
};

export const Footer = ({ classes }: TProps) => {
  // const sendAnalytics = (label: string) => {
  //   sendEventToCounters({ action: 'landing', label });
  // };

  return (
    <footer className={cn(styles.footer, classes)}>
      <div className={cn(styles.container, 'flex')}>
        <div className={cn(styles.copyright, 'flex')}>
          <p className={styles.brand}>© ВТБ</p>
          <div className={cn(styles.hh, 'flex')}>
            <img className={styles.hh_logo} src={hh_logo} alt="Логотип HH.ru" />
            <p className={styles.hh_text}>Бренд-центр 2024</p>
          </div>
        </div>
        <ul className={cn(styles.links, 'flex')}>
          <li className={styles.link_item}>
            <a
              className={styles.link}
              href={VACANCIES_HREF}
              target="_blank"
              rel="noreferrer"
              // onClick={() => sendAnalytics('vacancies')}
            >
              Вакансии
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
};
