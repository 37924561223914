import cn from 'classnames';
import styles from './Skill.module.scss';

type TProps = {
  title?: string;
  text?: string;
  classes?: string;
};

export const Skill = ({ title, text, classes }: TProps) => {
  return (
    <div className={cn(classes, styles.skill)}>
      <h3 className={styles.title}>{title}</h3>
      <p className={styles.text}>{text}</p>
    </div>
  );
};
