import { useUserAgent } from '@/src/hooks/useUserAgent';
import cn from 'classnames';
import styles from './VideoCard.module.scss';
import { isMobile } from 'react-device-detect';
import { Dispatch, SetStateAction, useRef, useState } from 'react';
import { useAppDispatch } from '@/src/hooks';
import { TBranch, setBranch } from '@/src/store/slices/gameSlice';
import { changeVideoTab } from '@/src/utils/changeVideoTab';

type TProps = {
  title: string;
  videoName: string;
  classes?: string;
  branch: TBranch;
  setCurrentStep: Dispatch<SetStateAction<string>>;
};

export const VideoCard = ({
  title,
  videoName,
  classes,
  branch,
  setCurrentStep,
}: TProps) => {
  const { browser } = useUserAgent();
  const isBrowserEdge = browser.name === 'Edge';
  const [isStarted, setIsStarted] = useState(false);
  const dispatch = useAppDispatch();

  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handleMouseOver = () => {
    if (!isStarted) {
      setIsStarted(true);
      videoRef?.current?.play();
    }
  };

  const handleSelectCard = () => {
    dispatch(setBranch(branch));

    setCurrentStep(branch + '_start');
    changeVideoTab(`${branch}/0_1_${branch}_start`);
  };

  return (
    <div
      className={cn(classes, styles.card)}
      onMouseOver={handleMouseOver}
      onClick={handleSelectCard}
    >
      <div className={styles.content}>
        <div className={styles.video}>
          <video
            ref={videoRef}
            preload={isMobile ? 'metadata' : 'none'}
            playsInline
            poster={`./videos/intro/posters/${videoName}.jpg`}
            width="100%"
            height="100%"
            muted
          >
            {isBrowserEdge ? (
              <>
                <source
                  src={`./videos/intro/${videoName}.mp4`}
                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={`./videos/intro/${videoName}.webm`}
                  type='video/webm;codecs="vp8, vorbis"'
                />
              </>
            ) : (
              <>
                <source
                  src={`./videos/intro/${videoName}.mp4`}
                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={`./videos/intro/${videoName}.webm`}
                  type='video/webm;codecs="vp8, vorbis"'
                />
              </>
            )}
          </video>
        </div>
        <p className={cn(styles.title, 'title_1')}>{title}</p>
      </div>
    </div>
  );
};
