import cn from 'classnames';
import styles from './Header.module.scss';
import logo from '../../images/logo.svg';

type TProps = {
  classes?: string;
};

export const Header = ({ classes }: TProps) => {
  return (
    <header className={cn(classes, styles.header)}>
      <div className={styles.logo}>
        <img src={logo} width={122} height={43} alt="Логотип ВТБ" />
      </div>
    </header>
  );
};
