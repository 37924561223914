export const changeVideoTab = (nextVideo: string) => {
  const videoTabs = document.querySelectorAll('.video_tab');

  videoTabs.forEach((tab) => {
    if ((tab as HTMLDivElement).dataset.id === nextVideo) {
      tab.classList.add('video_tab--active');
      const video = tab.querySelector('video');

      if (video) {
        video.play();
      }
    } else {
      tab.classList.remove('video_tab--active');
    }
  });
};
