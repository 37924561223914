import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useUserAgent } from '@/src/hooks/useUserAgent';
import { Loader } from '../Loader';

type TProps = {
  videoName: string;
  onEnded: () => void;
  onTimeUpdate: (event: any) => void;
};

function VideoContent({ videoName, onEnded, onTimeUpdate }: TProps) {
  const [isShowLoading, setIsShowLoading] = useState(false);
  const { browser } = useUserAgent();
  const isBrowserEdge = browser.name === 'Edge';

  const handleWaiting = () => {
    setIsShowLoading(true);
  };

  const handlePlaying = () => {
    setIsShowLoading(false);
  };

  return (
    <>
      <video
        preload={isMobile ? 'metadata' : 'none'}
        playsInline
        onEnded={onEnded}
        onTimeUpdate={onTimeUpdate}
        onWaiting={handleWaiting}
        onPlaying={handlePlaying}
        poster={`./videos/posters/${videoName}.jpeg`}
        width="100%"
        height="100%"
        onLoadStart={(video) => {
          process.env.NODE_ENV === 'development' &&
            ((video.target as HTMLVideoElement).playbackRate = 2.5);
        }}
      >
        {isBrowserEdge ? (
          <>
            <source
              src={`./videos/${videoName}.mp4`}
              type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
            />
            <source
              src={`./videos/${videoName}.webm`}
              type='video/webm;codecs="vp8, vorbis"'
            />
          </>
        ) : (
          <>
            <source
              src={`./videos/${videoName}.mp4`}
              type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
            />
            <source
              src={`./videos/${videoName}.webm`}
              type='video/webm;codecs="vp8, vorbis"'
            />
          </>
        )}
      </video>

      {isShowLoading && <Loader />}
    </>
  );
}

export default VideoContent;
