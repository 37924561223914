import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TBranch = 'department' | 'office';

interface IState {
  branch: TBranch | null;
  answers: string[];
}

const initialState: IState = {
  branch: null,
  answers: [],
};

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setBranch(state, action: PayloadAction<TBranch | null>) {
      state.branch = action.payload;
    },
    addAnswer(state, action: PayloadAction<string>) {
      state.answers.push(action.payload);
    },
    resetGame(state) {
      state.branch = null;
      state.answers = [];
    },
  },
});

export const { setBranch, addAnswer, resetGame } = gameSlice.actions;

export default gameSlice;
