import styles from './Choice.module.scss';
import { useState, useEffect, useRef, Dispatch, SetStateAction } from 'react';
import useLandscapeOrientation from '@/src/hooks/useOrientation';
import { CSSTransition } from 'react-transition-group';
import sendEventToCounters from '../../counterEvents';
import { GAME_STEPS } from '@/src/constants';
import { changeVideoTab } from '@/src/utils/changeVideoTab';
import cn from 'classnames';
import { useAppDispatch } from '@/src/hooks';
import { addAnswer } from '@/src/store/slices/gameSlice';

type TProps = {
  setIsVideoEnded: Dispatch<SetStateAction<boolean>>;
  setCurrentStep: Dispatch<SetStateAction<string>>;
  currentStep: string;
  isOpen: boolean;
  setIsVisibleChoice: Dispatch<SetStateAction<boolean>>;
};

export const Choice = ({
  setIsVideoEnded,
  setCurrentStep,
  currentStep,
  isOpen,
  setIsVisibleChoice,
}: TProps) => {
  const isLandscapeOrientation = useLandscapeOrientation();
  const [isVertical, setIsVertical] = useState(false);
  const [isVisibleButtons, setIsVisibleButtons] = useState(false);

  const dispatch = useAppDispatch();

  const buttonLeftRef = useRef(null);
  const buttonRightRef = useRef(null);
  const buttonsContainer = useRef(null);

  const data = GAME_STEPS[currentStep];

  const { buttons } = data;

  const sendAnalytics = (label: string) => {
    sendEventToCounters({ action: 'game', label });
  };

  useEffect(() => {
    if (isOpen) {
      setIsVisibleButtons(true);
    }
  }, [isOpen]);

  const handleClickOnButton = (next: string) => {
    setIsVisibleChoice(false);
    setIsVisibleButtons(false);
    setIsVideoEnded(false);
    setCurrentStep(next);

    const nextVideo = GAME_STEPS[next].videoName;

    dispatch(addAnswer(next));

    changeVideoTab(nextVideo);
  };

  // useEffect(() => {
  //   buttons?.forEach(({ nextStep }) => {
  //     const { videoName } = GAME_STEPS[nextStep];
  //     const tab = document.querySelector(`.video_tab[data-id="${videoName}"]`);
  //     const video = tab?.querySelector('video');
  //     if (video) {
  //       video.preload = 'auto';
  //     }
  //   });
  // }, [buttons]);

  useEffect(() => {
    if (!isLandscapeOrientation && window.innerWidth < 1019) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
  }, [isLandscapeOrientation]);

  return (
    <>
      <div
        className={cn(styles.root, {
          [styles.root_vertical]: isVertical,
          [styles.visible]: isOpen,
        })}
      >
        <div className={cn(styles.container, 'container')}>
          {buttons && buttons.length && (
            <CSSTransition in={isOpen} timeout={200} classNames="fadeIn">
              <div
                className={cn(styles.buttons, {
                  [styles.buttons_narrow]: buttons.length === 1,
                })}
                ref={buttonsContainer}
              >
                {buttons.map((button, i) => (
                  <button
                    className={cn(styles.button, 'btn_choice', {
                      [styles.button_left]: i === 0,
                      [styles.button_right]: i !== 0,
                      [styles.button_hidden]: !isVisibleButtons,
                    })}
                    ref={i === 0 ? buttonLeftRef : buttonRightRef}
                    key={`${button.text}_${Date.now()}`}
                    onClick={() => {
                      const { nextStep, analyticLabel } = button;
                      handleClickOnButton(nextStep);
                      if (analyticLabel) {
                        sendAnalytics(analyticLabel);
                      }
                    }}
                  >
                    <span>{button.text}</span>
                  </button>
                ))}
              </div>
            </CSSTransition>
          )}
        </div>
      </div>
    </>
  );
};
