import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';

import VideoContent from './VideoContent';
import { GAME_STEPS } from '@/src/constants';
import cn from 'classnames';

type TProps = {
  currentVideoName: string;
  setIsVideoEnded: Dispatch<SetStateAction<boolean>>;
  setIsVisibleChoice: Dispatch<SetStateAction<boolean>>;
};

export const Video = ({
  currentVideoName,
  setIsVideoEnded,
  setIsVisibleChoice,
}: TProps) => {
  const handleEndedVideo = () => {
    setIsVideoEnded(true);
  };

  const handleTimeUpdate = (event: ChangeEvent<HTMLVideoElement>) => {
    const { currentTime, duration } = event.target;

    const videoTabId = (event.target.parentNode as HTMLDivElement)?.dataset.id;

    if (currentTime >= duration - 2.5 && videoTabId === currentVideoName) {
      setIsVisibleChoice(true);
    }
  };

  return (
    <>
      {Object.entries(GAME_STEPS).map(([key, value]) => {
        const { videoName } = value;
        const isVisible = currentVideoName === videoName;
        // const isFirstVideo = key === 'intro';

        return (
          <div
            className={cn('video_tab', {
              'video_tab--active': isVisible,
            })}
            data-id={videoName}
            key={`${key}_${videoName}`}
          >
            <VideoContent
              videoName={videoName}
              onEnded={handleEndedVideo}
              onTimeUpdate={handleTimeUpdate}
            />
          </div>
        );
      })}
    </>
  );
};
