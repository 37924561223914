import cn from 'classnames';
// import styles from './VoteBtn.module.scss';
import { VOTE_HREF } from '@/src/constants';
import icon_heart from './images/icon_heart.png';
// import sendEventToCounters from '@/src/countersEvents.js';

type TProps = {
  text?: string;
  classes?: string;
};

export const VoteBtn = ({
  text = 'Поддержи ВТБ в\u00A0Рейтинге hh.ru',
  classes,
}: TProps) => {
  // const sendAnalytics = () => {
  // sendEventToCounters({ action: analytics_action, label: analytics_label });
  // };

  return (
    <a
      className={cn(classes, 'btn')}
      href={VOTE_HREF}
      target="_blank"
      rel="noreferrer"
      // onClick={sendAnalytics}
    >
      <img src={icon_heart} width={68} height={68} alt="" />
      {text}
    </a>
  );
};
