import { useMediaQuery } from 'usehooks-ts';
import cn from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { Skill } from '../Skill/Skill';
import { VoteBtn } from '@/src/components/VoteBtn/VoteBtn';
import { PlayBtn } from '@/src/components/PlayBtn';
import 'swiper/css';
import styles from './Final.module.scss';
import img_office from './images/poster_final_office.jpg';
import img_department from './images/poster_final_department.jpg';
import { useAppDispatch, useAppSelector } from '@/src/hooks';
import { resetGame } from '@/src/store/slices/gameSlice';
import { usePageContext } from '../App/PageContext';
import { noop } from '@/src/utils/noop';
import {
  currentGameAnswersSelector,
  currentGameBranchSelector,
} from '@/src/store/selectors/game';
import { FINAL_VIDEOS } from '@/src/constants';
import { useEffect, useRef } from 'react';

const skillsMock = [
  {
    title: 'Внимание к деталям',
    text: 'Ты знаешь, что именно детали помогают всей картине выглядеть целостно, и никогда не упускаешь их из виду',
  },
  {
    title: 'Системный подход',
    text: 'Ты знаешь, как связаны все элементы в твоей работе, и всегда стремишься к их гармоничному взаимодействию.',
  },
];

export const Final = () => {
  const isMobile = useMediaQuery('(max-width: 699px)');

  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;
  const branch = useAppSelector(currentGameBranchSelector) || 'department';
  const answers = useAppSelector(currentGameAnswersSelector);
  const dispatch = useAppDispatch();

  const isFuture =
    answers.includes('department_forward_100_years') ||
    answers.includes('office_forward_100_years');

  const finalVideoName = branch ? branch + (isFuture ? '_future' : '') : '';

  const finalVideo =
    FINAL_VIDEOS[finalVideoName as keyof typeof FINAL_VIDEOS] || '';

  console.log('finalVideo', finalVideo, branch, finalVideoName);

  const handleGameRetry = () => {
    dispatch(resetGame());

    changePage('preloader');
  };

  const videoRef = useRef<HTMLVideoElement | null>(null);

  useEffect(() => {
    if (videoRef.current) {
      // videoRef.current.muted = false;
    }
  }, []);

  return (
    <div className={styles.final}>
      <div className={cn(styles.container, 'container')}>
        <Header classes="header_final" />
        <div className={cn(styles.media, 'blur_edges')}>
          <div className={styles.media_blur}></div>
          {finalVideo ? (
            <div className={styles.media_video}>
              <video
                ref={videoRef}
                preload={isMobile ? 'metadata' : 'none'}
                playsInline
                poster={`./videos/final/posters/${finalVideo}.jpg`}
                width="100%"
                height="100%"
                autoPlay
                muted
              >
                <source
                  src={`./videos/final/${finalVideo}.mp4`}
                  type='video/mp4;codecs="avc1.42E01E, mp4a.40.2"'
                />
                <source
                  src={`./videos/final/${finalVideo}.webm`}
                  type='video/webm;codecs="vp8, vorbis"'
                />
              </video>
            </div>
          ) : (
            <img
              src={branch === 'office' ? img_office : img_department}
              width={1556}
              height={881}
              alt=""
            />
          )}
        </div>
        <div className={cn(styles.main)}>
          <div className={cn(styles.content)}>
            <h1 className={cn(styles.title)}>
              Укажи свою суперсилу в&#160;резюме!
            </h1>
            <div className={cn(styles.skills)}>
              {!isMobile ? (
                <div className={cn(styles.list)}>
                  {skillsMock.map(({ title, text }, i) => {
                    return <Skill key={i} title={title} text={text} />;
                  })}
                </div>
              ) : (
                <Swiper
                  watchOverflow={true}
                  slidesPerView={'auto'}
                  spaceBetween={10}
                  slideToClickedSlide={true}
                >
                  {skillsMock.map(({ title, text }, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <Skill key={i} title={title} text={text} />
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              )}
            </div>
          </div>
          <div className={cn(styles.btns, 'flex')}>
            <VoteBtn classes={cn(styles.btn_support, 'btn_bg', 'btn_heart')} />
            <PlayBtn
              classes={cn(styles.btn_play, 'btn', 'btn_car')}
              line1="Играть ещё раз"
              line2=""
              onClick={handleGameRetry}
            />
          </div>
        </div>
        <Footer classes={styles.footer} />
      </div>
    </div>
  );
};
