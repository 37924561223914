import cn from 'classnames';
import icon_car from './images/icon_car.png';

type TProps = {
  line1?: string;
  line2?: string;
  classes?: string;
  onClick: () => void;
};

export const PlayBtn = ({
  line1 = 'Загляни в\u00A0мир будущего',
  line2 = 'с\u00A0машиной\u00A0времени ВТБ',
  classes,
  onClick,
}: TProps) => {
  // const sendAnalytics = () => {
  // sendEventToCounters({ action: analytics_action, label: analytics_label });
  // };

  return (
    <button className={cn(classes, 'btn_play')} onClick={onClick}>
      <div className="btn_play__icon">
        <img src={icon_car} width={95} height={61} alt="" />
      </div>
      <p>
        {line1 && <span>{line1}</span>}
        {line2 && <span>{line2}</span>}
      </p>
    </button>
  );
};
