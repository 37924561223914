export const VOTE_HREF = '';
export const VACANCIES_HREF = '';

type TStepButton = {
  text: string;
  nextStep: string;
  analyticLabel: string;
};

type TStep = {
  videoName: string;
  buttons?: TStepButton[];
  isLast?: boolean;
};

type TSteps = Record<string, TStep>;

const DEPARTMENT_BRANCH_6_MONTHS_FORWARD = {
  department_forward_6_months: {
    videoName: 'department/1_2_department_forward_6_months',
    buttons: [
      {
        text: 'Спросить, где\u00A0её родители?',
        nextStep: 'department_ask_parents',
        analyticLabel: '',
      },
      {
        text: 'Предложить проследовать к\u00A0рабочему месту',
        nextStep: 'department_go_to_place',
        analyticLabel: '',
      },
    ],
  },

  department_ask_parents: {
    videoName: 'department/1_3_department_ask_parents',
    buttons: [
      {
        text: 'Предложить воды',
        nextStep: 'department_offer_water',
        analyticLabel: '',
      },
      {
        text: 'Спросить, какая помощь требуется',
        nextStep: 'department_ask_to_help',
        analyticLabel: '',
      },
    ],
  },

  department_go_to_place: {
    videoName: 'department/1_3_department_go_to_place',
    buttons: [
      {
        text: 'Предложить воды',
        nextStep: 'department_offer_water',
        analyticLabel: '',
      },
      {
        text: 'Спросить, какая помощь требуется',
        nextStep: 'department_ask_to_help',
        analyticLabel: '',
      },
    ],
  },

  department_offer_water: {
    videoName: 'department/1_4_department_offer_water',
    buttons: [
      {
        text: 'Спасибо!',
        nextStep: 'department_ask_to_help',
        analyticLabel: '',
      },
      {
        text: 'Постараюсь справиться!',
        nextStep: 'department_ask_to_help',
        analyticLabel: '',
      },
    ],
  },

  department_ask_to_help: {
    videoName: 'department/1_4_department_ask_to_help',
    buttons: [
      {
        text: 'Предложить оформить карту',
        nextStep: 'department_offer_card',
        analyticLabel: '',
      },
      {
        text: 'Посоветоваться с\u00A0руководителем',
        nextStep: 'department_consult_manager',
        analyticLabel: '',
      },
    ],
  },

  department_offer_card: {
    videoName: 'department/1_5_department_offer_card',
    buttons: [
      {
        text: 'Рассказать о\u00A0программах лояльности для\u00A0путешественников',
        nextStep: 'department_programs_for_travelers',
        analyticLabel: '',
      },
      {
        text: 'Предложить уникальный дизайн карты',
        nextStep: 'department_unique_design',
        analyticLabel: '',
      },
    ],
  },

  department_consult_manager: {
    videoName: 'department/1_7_department_consult_manager',
    buttons: [
      {
        text: 'Справиться самостоятельно',
        nextStep: 'department_programs_for_travelers',
        analyticLabel: '',
      },
      {
        text: 'Попросить Оксану о\u00A0помощи',
        nextStep: 'department_ask_for_help',
        analyticLabel: '',
      },
    ],
  },

  department_programs_for_travelers: {
    videoName: 'department/1_6_department_programs_for_travelers',
    isLast: true,
  },

  department_unique_design: {
    videoName: 'department/1_6_department_unique_design',
    isLast: true,
  },

  department_ask_for_help: {
    videoName: 'department/1_8_department_ask_for_help',
    isEnd: true,
  },
};

const DEPARTMENT_BRANCH_2_YEARS_FORWARD = {
  department_forward_2_years: {
    videoName: 'department/2_2_department_forward_2_years',
    buttons: [
      {
        text: 'Попрощаться с\u00A0клиентом',
        nextStep: 'department_goodbye',
        analyticLabel: '',
      },
      {
        text: 'Продолжить беседу с\u00A0клиентом',
        nextStep: 'department_goodbye',
        analyticLabel: '',
      },
    ],
  },

  department_goodbye: {
    videoName: 'department/2_3_department_goodbye',
    buttons: [
      {
        text: 'Согласиться на\u00A0звонок',
        nextStep: 'department_agree_call',
        analyticLabel: '',
      },
      {
        text: 'Попросить звонить в\u00A0рабочее время',
        nextStep: 'department_call_in_worktime',
        analyticLabel: '',
      },
    ],
  },

  department_agree_call: {
    videoName: 'department/2_4_department_agree_call',
    buttons: [
      {
        text: 'Идем домой',
        nextStep: 'department_go_next',
        analyticLabel: '',
      },
      {
        text: 'Идём болеть за\u00A0коллег',
        nextStep: 'department_go_next',
        analyticLabel: '',
      },
    ],
  },

  department_call_in_worktime: {
    videoName: 'department/2_4_department_call_in_worktime',
    buttons: [
      {
        text: 'Идём домой',
        nextStep: 'department_go_next',
        analyticLabel: '',
      },
      {
        text: 'Идём болеть за\u00A0коллег',
        nextStep: 'department_go_next',
        analyticLabel: '',
      },
    ],
  },

  department_go_next: {
    videoName: 'department/2_5_department_go_next',
    buttons: [
      {
        text: 'Оставить на\u00A0посту охраны',
        nextStep: 'department_leave_post',
        analyticLabel: '',
      },
      {
        text: 'Связаться с\u00A0клиентом',
        nextStep: 'department_call_client',
        analyticLabel: '',
      },
    ],
  },

  department_leave_post: {
    videoName: 'department/2_6_department_leave_post',
    isLast: true,
  },

  department_call_client: {
    videoName: 'department/2_6_department_call_client',
    isLast: true,
  },
};

const DEPARTMENT_BRANCH_100_YEARS_FORWARD = {
  department_forward_100_years: {
    videoName: 'department/3_2_department_forward_100_years',
    buttons: [
      {
        text: 'Выкладывай, в\u00A0чём дело',
        nextStep: 'department_try_help',
        analyticLabel: '',
      },
      {
        text: 'Попробую…',
        nextStep: 'department_try_help',
        analyticLabel: '',
      },
    ],
  },

  department_try_help: {
    videoName: 'department/3_3_department_try_help',
    buttons: [
      {
        text: 'Задай уточняющий вопрос',
        nextStep: 'department_clarifying_question',
        analyticLabel: '',
      },
      {
        text: 'Запроси видео оплаты',
        nextStep: 'department_payment_video',
        analyticLabel: '',
      },
    ],
  },

  department_clarifying_question: {
    videoName: 'department/3_4_department_clarifying_question',
    buttons: [
      {
        text: 'Подключи тройную верификацию мыслей',
        nextStep: 'department_verify_thoughts',
        analyticLabel: '',
      },
      {
        text: 'Отмени списание',
        nextStep: 'department_cancel_payment',
        analyticLabel: '',
      },
    ],
  },

  department_payment_video: {
    videoName: 'department/3_4_department_payment_video',
    buttons: [
      {
        text: 'Подключи тройную верификацию мыслей',
        nextStep: 'department_verify_thoughts',
        analyticLabel: '',
      },
      {
        text: 'Объясни правила пользования сервисом повторно',
        nextStep: 'department_explain_rules',
        analyticLabel: '',
      },
    ],
  },

  department_cancel_payment: {
    videoName: 'department/3_5_department_cancel_payment',
    buttons: [
      {
        text: 'Подключи тройную верификацию мыслей',
        nextStep: 'department_verify_thoughts',
        analyticLabel: '',
      },
      {
        text: 'Объясни правила пользования сервисом повторно',
        nextStep: 'department_explain_rules',
        analyticLabel: '',
      },
    ],
  },

  department_explain_rules: {
    videoName: 'department/3_6_department_explain_rules',
    isLast: true,
  },

  department_verify_thoughts: {
    videoName: 'department/3_5_department_verify_thoughts',
    isLast: true,
  },
};

const DEPARTMENT_BRANCH = {
  department_start: {
    videoName: 'department/0_1_department_start',
    buttons: [
      {
        text: 'Вперёд на\u00A06\u00A0месяцев',
        nextStep: 'department_forward_6_months',
        analyticLabel: '',
      },
      {
        text: 'Вперёд на\u00A02\u00A0года',
        nextStep: 'department_forward_2_years',
        analyticLabel: '',
      },
      {
        text: 'Вперёд на\u00A0100\u00A0лет',
        nextStep: 'department_forward_100_years',
        analyticLabel: '',
      },
    ],
  },

  ...DEPARTMENT_BRANCH_6_MONTHS_FORWARD,

  ...DEPARTMENT_BRANCH_2_YEARS_FORWARD,

  ...DEPARTMENT_BRANCH_100_YEARS_FORWARD,
};

const OFFICE_BRANCH_6_MONTHS_FORWARD = {
  office_forward_6_months: {
    videoName: 'office/1_2_office_forward_6_months',
    buttons: [
      {
        text: 'Помочь коллеге',
        nextStep: 'office_help_recheck',
        analyticLabel: '',
      },
      {
        text: 'Предложить всё перепроверить',
        nextStep: 'office_help_recheck',
        analyticLabel: '',
      },
    ],
  },

  office_help_recheck: {
    videoName: 'office/1_3_office_help_recheck',
    buttons: [
      {
        text: 'Проверь, вся ли база данных подтянулась…',
        nextStep: 'office_check_database',
        analyticLabel: '',
      },
      {
        text: 'Посоветуйся с\u00A0руководителем',
        nextStep: 'office_council_manager',
        analyticLabel: '',
      },
    ],
  },

  office_check_database: {
    videoName: 'office/1_4_office_check_database',
    buttons: [
      {
        text: 'Не пропустим!',
        nextStep: 'office_announcement',
        analyticLabel: '',
      },
      {
        text: 'Придём с\u00A0подарком',
        nextStep: 'office_announcement',
        analyticLabel: '',
      },
    ],
  },

  office_council_manager: {
    videoName: 'office/1_4_office_council_manager',
    buttons: [
      {
        text: 'Не пропустим!',
        nextStep: 'office_announcement',
        analyticLabel: '',
      },
      {
        text: 'Придём с\u00A0подарком',
        nextStep: 'office_announcement',
        analyticLabel: '',
      },
    ],
  },

  office_announcement: {
    videoName: 'office/1_5_office_announcement',
    buttons: [
      {
        text: 'А может на\u00A0Алтае?',
        nextStep: 'office_humorist',
        analyticLabel: '',
      },
      {
        text: 'Спасибо, но я люблю только сплавы из\u00A0драгметаллов',
        nextStep: 'office_humorist',
        analyticLabel: '',
      },
    ],
  },

  office_humorist: {
    videoName: 'office/1_6_office_humorist',
    isLast: true,
  },
};

const OFFICE_BRANCH_2_YEARS_FORWARD = {
  office_forward_2_years: {
    videoName: 'office/2_1_office_forward_2_years',
    buttons: [
      {
        text: 'Давайте сначала поедим',
        nextStep: 'office_discuss_tasks',
        analyticLabel: '',
      },
      {
        text: 'Говори, если не\u00A0терпится',
        nextStep: 'office_discuss_tasks',
        analyticLabel: '',
      },
    ],
  },

  office_discuss_tasks: {
    videoName: 'office/2_2_office_discuss_tasks',
    buttons: [
      {
        text: 'Согласиться',
        nextStep: 'office_agree',
        analyticLabel: '',
      },
      {
        text: 'Спросить мнение Татьяны',
        nextStep: 'office_ask_tatyana',
        analyticLabel: '',
      },
    ],
  },

  office_agree: {
    videoName: 'office/2_3_office_agree',
    buttons: [
      {
        text: 'Поблагодарить',
        nextStep: 'office_give_thanks',
        analyticLabel: '',
      },
      {
        text: 'Пригласить за\u00A0стол',
        nextStep: 'office_give_thanks',
        analyticLabel: '',
      },
    ],
  },

  office_ask_tatyana: {
    videoName: 'office/2_3_office_ask_tatyana',
    buttons: [
      {
        text: 'Поблагодарить',
        nextStep: 'office_give_thanks',
        analyticLabel: '',
      },
      {
        text: 'Пригласить за\u00A0стол',
        nextStep: 'office_give_thanks',
        analyticLabel: '',
      },
    ],
  },

  office_give_thanks: {
    videoName: 'office/2_4_office_give_thanks',
    buttons: [
      {
        text: 'Все дело в\u00A0команде, но\u00A0без грамотного руководства никуда!',
        nextStep: 'office_good_team',
        analyticLabel: '',
      },
      {
        text: 'Руководитель\u00A0– лишь часть крутой команды!',
        nextStep: 'office_good_team',
        analyticLabel: '',
      },
    ],
  },

  office_good_team: {
    videoName: 'office/2_5_office_good_team',
    isLast: true,
  },
};

const OFFICE_BRANCH_100_YEARS_FORWARD = {
  office_forward_100_years: {
    videoName: 'office/3_1_office_forward_100_years',
    buttons: [
      {
        text: 'Запросить мнение аналитика',
        nextStep: 'office_get_analytic',
        analyticLabel: '',
      },
      {
        text: 'Запустить исследование',
        nextStep: 'office_start_research',
        analyticLabel: '',
      },
    ],
  },

  office_start_research: {
    videoName: 'office/3_2_office_start_research',
    buttons: [
      {
        text: 'Назначить новую встречу',
        nextStep: 'office_new_meet',
        analyticLabel: '',
      },
      {
        text: 'Продолжить текущую встречу',
        nextStep: 'office_continue_meet',
        analyticLabel: '',
      },
    ],
  },

  office_get_analytic: {
    videoName: 'office/3_2_office_get_analytic',
    buttons: [
      {
        text: 'Назначить новую встречу',
        nextStep: 'office_new_meet',
        analyticLabel: '',
      },
      {
        text: 'Продолжить текущую встречу',
        nextStep: 'office_continue_meet',
        analyticLabel: '',
      },
    ],
  },

  office_new_meet: {
    videoName: 'office/3_3_office_new_meet',
    buttons: [
      {
        text: 'Одобрить решение',
        nextStep: 'office_approve',
        analyticLabel: '',
      },
      {
        text: 'Продумать передачу дел',
        nextStep: 'office_consider',
        analyticLabel: '',
      },
    ],
  },

  office_continue_meet: {
    videoName: 'office/3_3_office_continue_meet',
    buttons: [
      {
        text: 'Поздравить и\u00A0одобрить решение',
        nextStep: 'office_approve_karina',
        analyticLabel: '',
      },
      {
        text: 'Продумать передачу дел',
        nextStep: 'office_consider_karina',
        analyticLabel: '',
      },
    ],
  },

  office_approve: {
    videoName: 'office/3_4_office_approve',
    isLast: true,
  },

  office_consider: {
    videoName: 'office/3_4_office_consider',
    isLast: true,
  },

  office_approve_karina: {
    videoName: 'office/3_4_office_approve_karina',
    isLast: true,
  },

  office_consider_karina: {
    videoName: 'office/3_4_office_consider_karina',
    isLast: true,
  },
};

const OFFICE_BRANCH = {
  office_start: {
    videoName: 'office/0_1_office_start',
    buttons: [
      {
        text: 'Вперёд на\u00A06\u00A0месяцев',
        nextStep: 'office_forward_6_months',
        analyticLabel: '',
      },
      {
        text: 'Вперёд на\u00A02\u00A0года',
        nextStep: 'office_forward_2_years',
        analyticLabel: '',
      },
      {
        text: 'Вперёд на\u00A0100\u00A0лет',
        nextStep: 'office_forward_100_years',
        analyticLabel: '',
      },
    ],
  },

  ...OFFICE_BRANCH_6_MONTHS_FORWARD,

  ...OFFICE_BRANCH_2_YEARS_FORWARD,

  ...OFFICE_BRANCH_100_YEARS_FORWARD,
};

export const GAME_STEPS: TSteps = {
  ...DEPARTMENT_BRANCH,

  ...OFFICE_BRANCH,
};

export const FINAL_VIDEOS = {
  department: 'final_department_1',
  department_future: 'final_department_2',
  office: 'final_office_1',
  office_future: 'final_office_2',
};
