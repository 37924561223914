import UAParser from 'ua-parser-js';

export const useUserAgent = () => {
  const parser = new UAParser();

  const browser = parser.getBrowser();
  const device = parser.getDevice();

  return { browser, device };
};
