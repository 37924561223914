/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import useLandscapeOrientation from '@/src/hooks/useOrientation';
import cn from 'classnames';
import { GAME_STEPS } from '@/src/constants';
import { Video } from '@/src/components/Video';
import { Choice } from '@/src/components/Choice';
import { Header } from '@/src/components/Header';
import { Footer } from '@/src/components/Footer';
import { RotateScreen } from '@/src/components/RotateScreen/RotateScreen';
import { VideoCard } from '@/src/components/VideoCard';
import styles from './Game.module.scss';
import img_speaker from './images/speaker.png';
import { usePageContext } from '@/src/components/App/PageContext';
import { noop } from '@/src/utils/noop';

export const Game = () => {
  const pageContext = usePageContext();
  const changePage = pageContext?.setPage || noop;

  const [currentStep, setCurrentStep] = useState('intro');
  const [currentVideoName, setCurrentVideoName] = useState(
    currentStep === 'intro' ? 'intro' : GAME_STEPS[currentStep].videoName,
  );
  const [isVideoEnded, setIsVideoEnded] = useState(true);
  const [isVisibleChoice, setIsVisibleChoice] = useState(false);
  const [isLastVideo, setIsLastVideo] = useState(false);
  const [isNeedRollPhone, setIsNeedRollPhone] = useState(false);
  const isLandscapeOrientation = useLandscapeOrientation();

  useEffect(() => {
    if (currentStep === 'intro') {
      return;
    }
    const data = GAME_STEPS[currentStep];
    setCurrentVideoName(data.videoName);

    if (data.isLast) {
      setIsLastVideo(true);
    }
  }, [currentStep]);

  useEffect(() => {
    if (!isLandscapeOrientation && window.innerWidth < 700) {
      setIsNeedRollPhone(true);
    } else {
      setIsNeedRollPhone(false);
    }
  }, [isLandscapeOrientation]);

  // const rotate = false;
  useEffect(() => {
    if (isLastVideo && isVideoEnded) {
      changePage('final');
    }
  }, [isVideoEnded]);

  useEffect(() => {
    const video = document.querySelector(
      `.video_tab[data-id="${currentVideoName}"] video`,
    ) as HTMLVideoElement;

    if (isNeedRollPhone) {
      video?.pause();
    } else {
      video?.play();
    }
  }, [isNeedRollPhone, currentVideoName]);

  return (
    <div className={styles.game}>
      {currentStep !== 'intro' && isNeedRollPhone && <RotateScreen />}
      {currentStep === 'intro' && (
        <div className={styles.intro}>
          <div className={cn(styles.container, 'container')}>
            <Header />
            <div className={styles.intro_content}>
              <h1 className={cn(styles.intro_title, 'title_0')}>
                Выбери направление, в&#160;котором хочешь оказаться.
              </h1>
              <p className={cn(styles.intro_caption)}>
                И не забудь включить звук
                <img src={img_speaker} width={48} height={48} alt="" />
              </p>
              <div className={styles.intro_videos}>
                <VideoCard
                  classes={styles.intro_video}
                  videoName="intro_office"
                  title="Офис"
                  branch="office"
                  setCurrentStep={setCurrentStep}
                />
                <VideoCard
                  classes={styles.intro_video}
                  videoName="intro_department"
                  title="Отделение банка"
                  branch="department"
                  setCurrentStep={setCurrentStep}
                />
              </div>
            </div>
            <Footer classes={styles.info_footer} />
          </div>
        </div>
      )}
      {currentVideoName && (
        <Video
          setIsVideoEnded={setIsVideoEnded}
          currentVideoName={currentVideoName}
          setIsVisibleChoice={setIsVisibleChoice}
        />
      )}
      {currentStep !== 'intro' && !isNeedRollPhone && (
        <Choice
          isOpen={isVisibleChoice}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setIsVideoEnded={setIsVideoEnded}
          setIsVisibleChoice={setIsVisibleChoice}
        />
      )}
    </div>
  );
};
